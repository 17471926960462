import { memo, useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab as VCETab } from '@volvo/vce-uikit';
import type { ZoneTab } from '../../../../../../../context/zone/types';
import { useZoneContext } from '../../../../../../../context/zone/ZoneContext';

type Props = { tab: ZoneTab };

const TabComponent: FC<Props> = ({ tab }) => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useZoneContext();

  const onClick = useCallback(() => {
    setSelectedTab(tab);
  }, [setSelectedTab, tab]);

  return (
    <VCETab key={tab} onClick={onClick} active={selectedTab === tab}>
      {t(`details-panel.tabs.${tab}`)}
    </VCETab>
  );
};

export const Tab = memo(TabComponent);
