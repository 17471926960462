import styled from '@emotion/styled';
import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PointOfInterestType, ZoneType } from '@volvo/vce-package-site-mapcommon';
import { HorizontalMenu } from '@volvo/vce-uikit';
import { useFeaturesContext } from '../../context/features';
import { useMapContext } from '../../context/map';
import { useNavigationContext } from '../../context/navigation';
import { useSiteConfigContext } from '../../context/site-config/SiteConfigContext';
import { DrawingModes } from '../../types';
import { TopMenuItem } from './top-menu-items/TopMenuItem';

const TopMenuWrapper = styled.div`
  display: flex;
`;

export const TopMenu: FC = () => {
  const { units } = useSiteConfigContext();
  const { changeDrawingMode, setVisibility, disableSelect, setDisableSelect } = useMapContext();
  const { selected, setSelected, setLeftPanelOpen, isMobile, isTabletPortrait } =
    useNavigationContext();
  const { setFlowArrows } = useFeaturesContext();
  const { t } = useTranslation();

  const handleCreateZone = useCallback((): void => {
    setSelected(null);
    changeDrawingMode({
      mode: DrawingModes.zone,
      options: {
        zoneType: ZoneType.TRAFFIC_ZONE,
        name: '',
        description: '',
        maxSpeedMs: undefined,
        materialVariants: [],
        lengthUnit: units.length.unit,
        totalMaterialWeight: 0,
      },
    });
  }, [changeDrawingMode, setSelected, units.length.unit]);

  const handleCreatePOI = useCallback((): void => {
    setSelected(null);
    changeDrawingMode({
      mode: DrawingModes.point_of_interest,
      options: {
        pointOfInterestType: PointOfInterestType.GENERAL,
        pointOfInterestText: '',
      },
    });
  }, [changeDrawingMode, setSelected]);

  const showHeatmaps = useCallback((): void => {
    setSelected(selected?.type === 'heatmap' ? null : { type: 'heatmap' });
  }, [setSelected, selected?.type]);

  const handleSiteConfiguration = () => {
    setSelected({ type: 'site-configuration' });
  };

  const handleCreateFlow = () => {
    setDisableSelect(true);
    setLeftPanelOpen(false);
    setFlowArrows([]);
    setSelected({ type: 'flow', createNew: true });
    setVisibility({
      materialZones: true,
      pointsOfInterest: true,
      trafficZones: false,
      machines: false,
      snailTrails: false,
    });
  };

  if (disableSelect) {
    return null;
  }

  return (
    <TopMenuWrapper>
      <HorizontalMenu
        style={{
          width: '100%',
        }}
      >
        {isMobile || isTabletPortrait ? null : (
          <TopMenuItem
            onClick={showHeatmaps}
            text={t('top-menu.show-heatmaps')}
            disabled={disableSelect}
          />
        )}
        <TopMenuItem
          onClick={handleSiteConfiguration}
          text={t('top-menu.map-configuration')}
          disabled={disableSelect}
        />
        <TopMenuItem
          onClick={handleCreateZone}
          text={t('top-menu.create-zone')}
          disabled={disableSelect}
        />
        <TopMenuItem
          onClick={handleCreateFlow}
          text={t('top-menu.create-flow')}
          disabled={disableSelect}
        />
        <TopMenuItem
          onClick={handleCreatePOI}
          text={t('top-menu.create-poi')}
          disabled={disableSelect}
        />
      </HorizontalMenu>
    </TopMenuWrapper>
  );
};
