import type { DeckProps, LayersList, PickingInfo } from '@deck.gl/core';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { constants } from '@mapbox/mapbox-gl-draw';
import { ArcLayer, SolidPolygonLayer } from 'deck.gl';
import { type Feature } from 'geojson';
import { type FC } from 'react';
import {
  Layer,
  Source,
  useControl,
  type IControl,
  type LayerProps,
  type MapInstance,
  type SourceProps,
} from 'react-map-gl';
import { ElementType, FaultyUnloadType } from '@volvo/vce-package-site-mapcommon';
import { autoFormatter, type Units } from '@volvo/vce-package-units';
import { useFeaturesContext } from '../../../../context/features';
import { createFlowFeature } from '../../../../context/features/createFeatures';
import { useMapContext } from '../../../../context/map';
import { useNavigationContext } from '../../../../context/navigation';
import { useSiteConfigContext } from '../../../../context/site-config/SiteConfigContext';
import {
  sourceTypes,
  type CustomFeatureLoadTicket,
  type CustomFeatureTrip,
  type CustomFeatureZone,
  type SourceType,
} from '../../../../types';
import { filterFeatureCollection } from '../../helpers';
import {
  deviceEventLayers,
  faultyUnloadLayers,
  flowLayers,
  materialZoneLayers,
  restrictedEventLayers,
  siteBoundaryLayers,
  snailTrailLayers,
  speedingEventLayers,
  trafficZoneLayers,
  transportEventLayers,
} from '../../layer-styles';
import { clusterRadius } from '../../Map';

const layerMapping: { [key in SourceType]: LayerProps[] } = {
  siteBoundary: siteBoundaryLayers,
  pointsOfInterest: [],
  snailTrails: snailTrailLayers,
  trafficZones: trafficZoneLayers,
  materialZones: materialZoneLayers,
  faultyUnloads: faultyUnloadLayers,
  machines: [],
  overlayImages: [],
  flows: flowLayers,
  speedingEvents: speedingEventLayers,
  restrictedEvents: restrictedEventLayers,
  transportEvents: transportEventLayers,
  deviceEvents: deviceEventLayers,
  trips: [],
  materialZones3D: [],
  loadTickets: [],
};

// This sort order must match the order in wich the layers beforeId is set in the LayerProps for each layer
// For example, the siteBoundary layers must have beforeId set to the trafficZone layer id.
const sortOrder: { [key in SourceType]: number } = {
  overlayImages: 0,
  siteBoundary: 1,
  trafficZones: 2,
  materialZones: 3,
  snailTrails: 4,
  pointsOfInterest: 5,
  flows: 6,
  faultyUnloads: 7,
  speedingEvents: 8,
  machines: 9,
  restrictedEvents: 10,
  transportEvents: 11,
  deviceEvents: 12,
  trips: 13,
  materialZones3D: 14,
  loadTickets: 15,
};

function DeckGLOverlay(props: DeckProps) {
  const overlay = useControl(
    () => new MapboxOverlay(props) as IControl<MapInstance>,
  ) as MapboxOverlay;
  overlay.setProps(props);
  return null;
}

export const SiteMapSources: FC = () => {
  const { selected, setSelected } = useNavigationContext();
  const { mapEdit, visibility } = useMapContext();
  const { featureCollection, heatmapCollection } = useFeaturesContext();
  const { units } = useSiteConfigContext();

  if (mapEdit) {
    return null;
  }

  const getTooltip = ({ object, layer }: PickingInfo<CustomFeatureZone>) => {
    if (!object || !layer || layer.id !== 'SolidPolygonLayer') {
      return null;
    }

    const zone = object as CustomFeatureZone;

    const materialBestUnit = autoFormatter(zone.properties.totalMaterialWeight, {
      unit: 'kg',
      preferredUnit: units.mass.unit as Units,
      toBest: true,
    });

    return {
      html: `<div>
        <div style="font-size: 14px; font-weight: bold">${zone.properties.name}</div>
        Total Material Weight: ${materialBestUnit}
      </div>`,
      style: {
        backgroundColor: '#fff',
        border: '1px solid #000',
        borderRadius: '5px',
        color: '#000',
        margin: '10px 0',
      },
    };
  };

  const visibleSourceTypes = sourceTypes.filter((st) => visibility[st]);
  // const sources: { sortOrder: number; source: React.ReactElement }[] = [];

  const sourceProps: { sortOrder: number; sourceProps: SourceProps; layerProps: LayerProps[] }[] =
    [];

  const deckGlLayers: LayersList = [];
  // Add a source for all source types. If the source type is not visible, the data will be empty but their layer(s) will still be rendered.
  sourceTypes.forEach((sourceType) => {
    const isVisibile = visibleSourceTypes.includes(sourceType);
    const filteredFeatures: Feature[] = isVisibile
      ? filterFeatureCollection(sourceType, [...featureCollection, ...heatmapCollection])
      : [];

    const layers = layerMapping[sourceType];
    if (sourceType === 'trips') {
      const data = filteredFeatures.filter((x) => x.properties?.element_type === ElementType.TRIP);

      deckGlLayers.push(
        new ArcLayer<CustomFeatureTrip>({
          id: 'deckgl-trips-arcs',
          data,
          getSourcePosition: (d) => d.properties.from as [number, number],
          getTargetPosition: (d) => d.properties.to as [number, number],
          getSourceColor: () => [0, 255, 0],
          getTargetColor: () => [0, 0, 255],
          getWidth: (d) => d.properties.count,
          getHeight: () => 4,
          getTilt: () => 15,
          widthScale: 4,
          pickable: true,
          autoHighlight: true,
          onClick(pickingInfo) {
            if (pickingInfo.object) {
              const f = featureCollection.find(
                (x) =>
                  x.id === pickingInfo.object.id && x.properties.element_type === ElementType.TRIP,
              ) as CustomFeatureTrip;
              if (f) {
                setSelected({ type: 'feature', feature: f, isNew: false });
              }
            }
          },
        }),
      );
    }

    if (sourceType === 'materialZones3D') {
      let maxMaterialWeight = 1;

      if (filteredFeatures.length) {
        maxMaterialWeight = (
          filteredFeatures.reduce((prev, current) => {
            if (!prev) {
              return current;
            }

            return (prev as unknown as CustomFeatureZone).properties.totalMaterialWeight >
              (current as unknown as CustomFeatureZone).properties.totalMaterialWeight
              ? prev
              : current;
          }) as unknown as CustomFeatureZone
        ).properties.totalMaterialWeight;
      }

      const layer = new SolidPolygonLayer<CustomFeatureZone>({
        id: 'SolidPolygonLayer',
        data: filteredFeatures,
        extruded: true,
        wireframe: true,
        getPolygon: (d: CustomFeatureZone) => d.geometry.coordinates as [number, number][][],
        getElevation: (d: CustomFeatureZone) =>
          (d.properties.totalMaterialWeight / maxMaterialWeight) * 200,
        getLineColor: [118, 186, 255],
        getFillColor: [118, 186, 255],
        getPolygonOffset: () => [10, 10],
        pickable: true,
        autoHighlight: true,
        onClick(pickingInfo) {
          if (pickingInfo.object) {
            const f = featureCollection.find(
              (x) => x.id === pickingInfo.object.id,
            ) as CustomFeatureTrip;
            if (f) {
              setSelected({ type: 'feature', feature: f, isNew: false });
            }
          }
        },
      });

      deckGlLayers.push(layer);
    }

    if (sourceType === 'loadTickets') {
      const layer = new ArcLayer<CustomFeatureLoadTicket>({
        id: 'deckgl-loadtickets-arcs',
        data: filteredFeatures,
        getSourcePosition: (d) => d.properties.from as [number, number],
        getTargetPosition: (d) => d.properties.to as [number, number],
        getWidth: (d: CustomFeatureLoadTicket) =>
          Math.max(1, d.properties.percentageOfTotalMoved / 10),
        getSourceColor: (d) => [0, 0, (255 * d.properties.percentageOfTotalMoved) / 100],
        getTargetColor: [0, 0, 0],
        pickable: true,
        getPolygonOffset: () => [100, 10],
        autoHighlight: true,
        getTilt: (d) => ((d.properties.totalQuantityMoved % 30) + 10) * 2,
        onClick(pickingInfo) {
          if (pickingInfo.object) {
            const f = featureCollection.find(
              (x) => x.id === pickingInfo.object.id,
            ) as CustomFeatureLoadTicket;
            if (f) {
              setSelected({ type: 'feature', feature: f, isNew: false });
            }
          }
        },
      });

      deckGlLayers.push(layer);
    }

    if (!layers || layers.length === 0) {
      return;
    }

    const sourcePropObj: any = {
      id: sourceType,
      type: 'geojson',
      data: {
        type: constants.geojsonTypes.FEATURE_COLLECTION,
        features: filteredFeatures,
      },
    };

    if (sourceType === 'faultyUnloads') {
      const warnings = ['==', ['get', 'status_string'], FaultyUnloadType.WARNING];
      const errors = ['==', ['get', 'status_string'], FaultyUnloadType.ERROR];

      sourcePropObj.cluster = true;
      sourcePropObj.clusterRadius = clusterRadius;
      sourcePropObj.clusterProperties = {
        warnings: ['+', ['case', warnings, 1, 0]],
        errors: ['+', ['case', errors, 1, 0]],
      };
    }

    if (sourceType === 'flows') {
      if (selected?.type !== 'flow' || !selected.flow) {
        return;
      }

      sourcePropObj.data.features = [createFlowFeature(selected.flow)];
    }

    const sourcePropWrapper = {
      sortOrder: sortOrder[sourceType],
      sourceProps: sourcePropObj,
      layerProps: layers,
    };

    sourceProps.push(sourcePropWrapper);
  });

  const sortedSourceProps = sourceProps.sort((a, b) => b.sortOrder - a.sortOrder);

  const flatMapLayers = sortedSourceProps.flatMap((s) => s.layerProps);
  for (let i = 0; i < flatMapLayers.length; i++) {
    if (i > 0) {
      flatMapLayers[i].beforeId = flatMapLayers[i - 1].id;
    }
  }

  return (
    <>
      {sortedSourceProps.map((s) => {
        return (
          <Source {...s.sourceProps} key={s.sourceProps.id}>
            {s.layerProps.map((l) => (
              <Layer {...l} key={l.id} />
            ))}
          </Source>
        );
      })}

      {deckGlLayers.length > 0 && <DeckGLOverlay layers={[deckGlLayers]} getTooltip={getTooltip} />}
    </>
  );
};
