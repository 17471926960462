import styled from '@emotion/styled';
import { type FC } from 'react';
import { MapProvider as MapProviderGl } from 'react-map-gl';
import { SystemIcon } from '@volvo/vce-uikit';
import { FaultyUnloadsProvider } from '../../../context/faulty-unloads/FaultyUnloadsContext';
import { FeaturesProvider } from '../../../context/features';
import { FlowsProvider } from '../../../context/flows';
import { MachinesProvider } from '../../../context/machines';
import { MapProvider } from '../../../context/map';
import { NavigationProvider } from '../../../context/navigation';
import { SiteProvider } from '../../../context/site';
import { SiteConfigProvider } from '../../../context/site-config/SiteConfigContext';
import { WebsocketProvider } from '../../../context/websocket';
import { MapWrapper } from '../map-wrapper/MapWrapper';

type Props = { siteId: string; baseUrl: string };

const HiddenDiv = styled.div`
  visibility: hidden;
  height: 0;
  width: 0;
`;

export const MapWithProviders: FC<Props> = ({ siteId, baseUrl }) => (
  <MapProviderGl>
    <HiddenDiv>
      <SystemIcon icon={'wind'} />
      <SystemIcon icon={'refresh'} />
    </HiddenDiv>
    <SiteProvider siteId={siteId}>
      <SiteConfigProvider siteId={siteId}>
        <WebsocketProvider>
          <MapProvider>
            <FeaturesProvider>
              <FlowsProvider>
                <NavigationProvider baseUrl={baseUrl}>
                  <MachinesProvider>
                    <FaultyUnloadsProvider>
                      <MapWrapper />
                    </FaultyUnloadsProvider>
                  </MachinesProvider>
                </NavigationProvider>
              </FlowsProvider>
            </FeaturesProvider>
          </MapProvider>
        </WebsocketProvider>
      </SiteConfigProvider>
    </SiteProvider>
  </MapProviderGl>
);
