import styled from '@emotion/styled';
import { type FC } from 'react';
import { Button, EmptyState } from '@volvo/vce-uikit';

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageErrorMessage: FC = () => (
  <CenterDiv>
    <EmptyState
      bodyText="The connection timed out, please refresh and try again."
      headingText="We’re sorry, something got lost on the way"
      icon="wind"
      size="large"
    >
      <Button iconStart="refresh" variant="secondary" onClick={() => location.reload()}>
        Refresh
      </Button>
    </EmptyState>
  </CenterDiv>
);
