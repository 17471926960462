import styled from '@emotion/styled';
import { DeviceMaxSizes } from '../../helpers/hooks/useDeviceSize';

export const ButtonWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bg};
  display: grid;
  padding: ${(props) => props.theme.map.layout.padding};
  border-top: 1px solid ${(props) => props.theme.colors.borderSubtle};
  grid-template-columns: repeat(auto-fit, minmax(140px, max-content));
  gap: 10px;
  justify-content: end;
  @media (width < ${DeviceMaxSizes.mobile}px) {
    direction: rtl;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const LeftColumn = styled.td`
  vertical-align: middle;
  padding-right: ${(props) => props.theme.map.layout.padding} !important;
  width: 50%;
`;

export const LeftColumnNarrow = styled(LeftColumn)`
  width: 25%;
  font-weight: 700;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 10;
  font-face: 'Volvo Novum', Arial, sans-serif;
  tr td {
    vertical-align: middle;
    padding: 2px;
  }
`;

export const StyledRow = styled.tr`
  border-spacing: 10px;
`;

export const Main = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;

export const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 0;
`;
