import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {"context":{"clientName":"emob"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  _Any: { input: any; output: any; }
  federation__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type EmobAsset = {
  alias?: Maybe<Scalars['String']['output']>;
  assetCurrentStatus?: Maybe<EmobAssetCurrentStatus>;
  assetType?: Maybe<EmobAssetType>;
  chassisId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  hibooExternalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pin17?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type EmobAssetCurrentStatus = {
  assetId?: Maybe<Scalars['String']['output']>;
  chargingStatus?: Maybe<EmobChargingStatus>;
  createdDateTime?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  zoneId?: Maybe<Scalars['String']['output']>;
  zoneType?: Maybe<Scalars['String']['output']>;
};

export enum EmobAssetGroups {
  Charger = 'CHARGER',
  Vehicle = 'VEHICLE'
}

export type EmobAssetQueries = {
  /** @deprecated This query will be replaced by new operator services */
  emobAssetBySiteId: Array<EmobAsset>;
};


export type EmobAssetQueriesEmobAssetBySiteIdArgs = {
  site: Scalars['ID']['input'];
};

export type EmobAssetType = {
  assetGroup: EmobAssetGroups;
  brand: Scalars['String']['output'];
  connectorTypes?: Maybe<Array<EmobConnectorType>>;
  createdDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type EmobAssetTypeInput = {
  assetGroup?: InputMaybe<EmobAssetGroups>;
  brand?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
};

export type EmobAssetTypeQueries = {
  /** @deprecated This query will be replaced by new operator services */
  emobAssetTypes: Array<EmobAssetType>;
};


export type EmobAssetTypeQueriesEmobAssetTypesArgs = {
  assetTypes?: InputMaybe<Array<EmobAssetTypeInput>>;
};

export enum EmobChargerConnectorTypes {
  Type_1 = 'TYPE_1',
  Type_1Combo_1 = 'TYPE_1_COMBO_1',
  Type_2 = 'TYPE_2',
  Type_2Combo_2 = 'TYPE_2_COMBO_2'
}

export enum EmobChargingStatus {
  Charging = 'Charging',
  ChargingDone = 'ChargingDone',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Interrupted = 'Interrupted'
}

export type EmobConnectorType = {
  assetTypeId: Scalars['String']['output'];
  chargerConnectorType: EmobChargerConnectorTypes;
  createdDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  _service: _Service;
  /** @deprecated This query will be replaced by new operator services */
  emobAssetTypes: EmobAssetTypeQueries;
  /** @deprecated This query will be replaced by new operator services */
  emobAssets: EmobAssetQueries;
};

export type _Service = {
  sdl?: Maybe<Scalars['String']['output']>;
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type QueryEmobAssetTypesVariables = Exact<{
  assetTypes?: InputMaybe<Array<EmobAssetTypeInput> | EmobAssetTypeInput>;
}>;


export type QueryEmobAssetTypes = { emobAssetTypes: { emobAssetTypes: Array<{ id: string, assetGroup: EmobAssetGroups, model: string, brand: string, createdDateTime: any, updatedDateTime?: any | null, connectorTypes?: Array<{ id: string, assetTypeId: string, chargerConnectorType: EmobChargerConnectorTypes }> | null }> } };

export type EmobAssetsVariables = Exact<{
  site: Scalars['ID']['input'];
}>;


export type EmobAssets = { emobAssets: { emobAssetBySiteId: Array<{ assetCurrentStatus?: { zoneId?: string | null, chargingStatus?: EmobChargingStatus | null } | null }> } };


export const QueryEmobAssetTypesDocument = gql`
    query QueryEmobAssetTypes($assetTypes: [EmobAssetTypeInput!]) {
  emobAssetTypes {
    emobAssetTypes(assetTypes: $assetTypes) {
      id
      assetGroup
      model
      brand
      createdDateTime
      updatedDateTime
      connectorTypes {
        id
        assetTypeId
        chargerConnectorType
      }
    }
  }
}
    `;

/**
 * __useQueryEmobAssetTypes__
 *
 * To run a query within a React component, call `useQueryEmobAssetTypes` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmobAssetTypes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmobAssetTypes({
 *   variables: {
 *      assetTypes: // value for 'assetTypes'
 *   },
 * });
 */
export function useQueryEmobAssetTypes(baseOptions?: Apollo.QueryHookOptions<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>(QueryEmobAssetTypesDocument, options);
      }
export function useQueryEmobAssetTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>(QueryEmobAssetTypesDocument, options);
        }
export function useQueryEmobAssetTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>(QueryEmobAssetTypesDocument, options);
        }
export type QueryEmobAssetTypesHookResult = ReturnType<typeof useQueryEmobAssetTypes>;
export type QueryEmobAssetTypesLazyQueryHookResult = ReturnType<typeof useQueryEmobAssetTypesLazyQuery>;
export type QueryEmobAssetTypesSuspenseQueryHookResult = ReturnType<typeof useQueryEmobAssetTypesSuspenseQuery>;
export type QueryEmobAssetTypesQueryResult = Apollo.QueryResult<QueryEmobAssetTypes, QueryEmobAssetTypesVariables>;
export const EmobAssetsDocument = gql`
    query EmobAssets($site: ID!) {
  emobAssets {
    emobAssetBySiteId(site: $site) {
      assetCurrentStatus {
        zoneId
        chargingStatus
      }
    }
  }
}
    `;

/**
 * __useEmobAssets__
 *
 * To run a query within a React component, call `useEmobAssets` and pass it any options that fit your needs.
 * When your component renders, `useEmobAssets` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmobAssets({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useEmobAssets(baseOptions: Apollo.QueryHookOptions<EmobAssets, EmobAssetsVariables> & ({ variables: EmobAssetsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmobAssets, EmobAssetsVariables>(EmobAssetsDocument, options);
      }
export function useEmobAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmobAssets, EmobAssetsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmobAssets, EmobAssetsVariables>(EmobAssetsDocument, options);
        }
export function useEmobAssetsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmobAssets, EmobAssetsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmobAssets, EmobAssetsVariables>(EmobAssetsDocument, options);
        }
export type EmobAssetsHookResult = ReturnType<typeof useEmobAssets>;
export type EmobAssetsLazyQueryHookResult = ReturnType<typeof useEmobAssetsLazyQuery>;
export type EmobAssetsSuspenseQueryHookResult = ReturnType<typeof useEmobAssetsSuspenseQuery>;
export type EmobAssetsQueryResult = Apollo.QueryResult<EmobAssets, EmobAssetsVariables>;