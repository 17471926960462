import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { Flow } from '../../../../../../context/features';
import { Body } from '../../../../typography/Typography';
import { Row } from '../row/Row';
import { Container } from './styles';

type Props = {
  metrics: Flow['siteMetricDefinitions'];
};

export const Metrics: FC<Props> = ({ metrics }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {metrics.map(({ name, description, type }) => (
        <Row
          title={t(`details-panel.flow.metrics.${type}`) + ':'}
          body={<Body text={`${name}${description ? ` (${description})` : ''}`} />}
        />
      ))}
    </Container>
  );
};
