import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loadrite, Tamtron, Vei } from '@volvo/vce-package-site-uikit';
import {
  ExternalReferenceTypes,
  type FragmentMaterialFlows,
} from '../../../../../../gql-types/generated-types-super-graph';
import { Body, Title } from '../../../../typography/Typography';
import { VolvoAssist } from './icons/VolvoAssist';
import { Container, Wrapper } from './styles';

type Props = {
  externalReferences: NonNullable<FragmentMaterialFlows['externalReferences']>;
};

export const ExternalReferences: FC<Props> = ({ externalReferences }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Title text={t('details-panel.flow.external-reference.header')} />
      <Wrapper>
        {externalReferences.map((externalReference) => (
          <Container key={externalReference.id}>
            {externalReference.type === ExternalReferenceTypes.Loadrite ? (
              <Loadrite />
            ) : externalReference.type === ExternalReferenceTypes.Tamtron ? (
              <Tamtron />
            ) : externalReference.type === ExternalReferenceTypes.VolvoAssist ? (
              <VolvoAssist />
            ) : externalReference.type === ExternalReferenceTypes.Vei ? (
              <Vei />
            ) : null}
            <Body
              key={externalReference.id}
              text={`${
                i18n.exists(`details-panel.flow.external-reference.types.${externalReference.type}`)
                  ? t(`details-panel.flow.external-reference.types.${externalReference.type}`)
                  : externalReference.type
              } - ${externalReference.value}`}
            />
          </Container>
        ))}
      </Wrapper>
    </div>
  );
};
